
import { Component, Vue } from "vue-property-decorator";
import i18n from "../../plugins/i18n";
import { Watch } from "vue-property-decorator";

import JudgeTypeService from "../../services/sporteventCalculation/JudgeTypeService";
import JudgeType from "../../models/sporteventCalculation/JudgeType";

@Component({})
export default class JudgeTypesComponent extends Vue {
  // Loading
  public loading = true;
  // Table
  public items: JudgeType[] = [];
  public fields = [
    {
      key: "name",
      sortable: true,
      label: i18n.tc("labels.name"),
    },
    {
      key: "shortname",
      sortable: true,
      label: i18n.tc("labels.shortname"),
    },
    {
      key: "actions",
      sortable: false,
      label: "",
    },
  ];
  public filter = "";
  public perPage = 10;
  public pageOptions = [5, 10, 20, 30];
  public totalRows = 1;
  public currentPage = 1;

  getAll(): void {
    JudgeTypeService.getAll().then((items: JudgeType[]) => {
      this.items = items;
      if (this.items) {
        this.totalRows = this.items.length;
      }
      this.loading = false;
    });
  }
  @Watch("$route", { immediate: true, deep: true })
  onUrlChange(): void {
    this.getAll();
  }
}
